import React from "react";
import { BrowserRouter as Route, Link, useRouteMatch } from "react-router-dom";
import { useSpring, animated } from "react-spring";

function Header() {
  const fadeUp = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(-250px)",
      transition: "0.1s ease-out",
    },
    to: { opacity: 1, transform: "translateY(0)" },
  }); // animate text up

  return (
    <>
      <div className="col-12 d-flex justify-content-center">
        <animated.div style={fadeUp} className="header">
          <Link to="/">
            <img src="../assets/1000formats-logo.png" className="animate__animated animate__pulse animate__infinite" />
          </Link>
        </animated.div>
      </div>
    </>
  );
}

export default Header;
