import React from "react";
import { useSpring, animated } from "react-spring";

function Logo() {
  const fadeLeft = useSpring({
    from: {
      opacity: 0,
      transform: "translateX(250px)",
      transition: "0.1s ease-out",
    },
    to: { opacity: 1, transform: "translateX(0)" },
  }); // animate text up

  return (
    <>
      <div className="heads-logo">
        <animated.div style={fadeLeft}>
          <a href="http://1000heads.today/" target="__blank">
            <img alt="1000heads logo" src="../assets/1000apps-logowhite.png" />
          </a>
        </animated.div>
      </div>
    </>
  );
}

export default Logo;
