import React from "react";
import { BrowserRouter as Route, Link, useRouteMatch } from "react-router-dom";
import platformsJSON from "../Templates/platforms.json";
import { useSpring, animated } from "react-spring";

function Nav(props) {
  const platforms = platformsJSON.platforms;

  function MenuLink({ img, label, to, activeOnlyWhenExact }) {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact,
    });
    return (
      <div className={match ? "active col-12" : "col-12"}>
        {match && " "}
        <Link to={to}>
          <img alt="icon-nav" src={img} />
          <p>{label}</p>
        </Link>
      </div>
    );
  } // render MenuLink with img and text

  const fadeUp = useSpring({
    from: {
      opacity: 0,
      transform: "translateY(250px)",
      transition: "0.1s ease-out",
    },
    to: { opacity: 1, transform: "translateY(0)" },
  }); // animate text up

  return (
    <>
      <div className="col-12 nav">
        <animated.div style={fadeUp} className="col-12">
          {platforms.map((platforms, index) => (
            <div className="links" key={index}>
              <MenuLink to={platforms.title} img={platforms.logo} label={platforms.title}></MenuLink>
            </div>
          ))}
        </animated.div>
      </div>
    </>
  );
}

export default Nav;
