import React, { useState } from "react";
import fbJSON from "../Templates/facebook.json";
import Collapse from "react-bootstrap/Collapse";
import Nav from "../Components/Nav";

function Facebook() {
  const formats = fbJSON;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Nav />
      <div className="container">
        <div id="toolbar" className="row">
          <div className="col-8 p-0">
            <p>Facebook Content Type</p>
            <div className="pill">
              <p>Paid</p>
            </div>
            <div className="pill">
              <p>Organic</p>
            </div>
          </div>
          <div className="col-4">
            <input className="d-flex justify-content-end" placeholder="Search Formats" />
          </div>
        </div>
        <div className="row">
          {formats.map((format, index) => (
            <main className="col-6 col-lg-4">
              <div class="social-card">
                <div className="img-card"></div>
                <div className="pill">
                  <p>{format.category}</p>
                </div>
                <h3>{format.content}</h3>
                <div>
                  <a target="_blank" href={format.infolink}>
                    <button disabled={!format.infolink}>More Info</button>
                  </a>
                  <a target="_blank" href={format.example}>
                    <button disabled={!format.example}>Example</button>
                  </a>
                </div>
                <p>{format.description}</p>
                <p>{format.protips}</p>
                <p onClick={() => setOpen(!open)} className="pink">
                  + Show Specs
                </p>
                <Collapse in={open}>
                  <div id={`example-collapse-text-${index}`} className="specs">
                    <div>
                      <p>
                        <span>Organic:</span> {format.organic ? "True" : "False"}{" "}
                      </p>
                      <p>
                        <span>Paid:</span> {format.paid ? "True" : "False"}{" "}
                      </p>
                      <p>
                        <span>Format:</span> {format.format ? format.format : "n/a"}
                      </p>
                      <p>
                        <span>Recommend:</span> {format.reccomend ? format.reccomend : "n/a"}
                      </p>
                      <p>
                        <span>Ratio:</span> {format.ratio ? format.ratio : "n/a"}
                      </p>
                      <p>
                        <span>Image Size:</span> {format.imgsize ? format.imgsize : "n/a"}
                      </p>
                      <p>
                        <span>Text Limit:</span> {format.textlimit ? format.textlimit : "n/a"}
                      </p>
                    </div>
                  </div>
                </Collapse>
              </div>
            </main>
          ))}
        </div>
      </div>
    </>
  );
}

export default Facebook;
