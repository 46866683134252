import React, { useState } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import Header from "./Components/Header";

import Logo from "./Components/Logo";
import Creators from "./Components/Creators";
import Footer from "./Components/Footer";
import Launcher from "./Views/Launcher";
import Facebook from "./Views/Facebook";
import ErrorPage from "./Views/Error";

function App() {
  return (
    <>
      <BrowserRouter>
        <Creators />
        <Header />
        <Logo />
        <Switch>
          <Route exact path="/" component={Launcher} />
          <Route path="/facebook" component={Facebook} />
          <Route component={ErrorPage} />
        </Switch>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
