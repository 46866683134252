import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSpring, animated } from "react-spring";

function Creators() {
  const fadeRight = useSpring({
    from: {
      opacity: 0,
      transform: "translateX(-250px)",
      transition: "0.1s ease-out",
    },
    to: { opacity: 1, transform: "translateX(0)" },
  }); // animate text up

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="creators">
        <animated.div style={fadeRight}>
          <p
            className={open ? "active" : ""}
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}>
            Team <br /> Power{" "}
          </p>
        </animated.div>
      </div>

      <div>
        <Collapse in={open}>
          <div id="example-collapse-text" className="people">
            <main className="container">
              <div className="row w-75 mx-auto">
                <div className="col-6">
                  <img alt="person 1" src="../assets/icon_person.png" />
                  <img alt="person 2" src="../assets/icon_person.png" />
                  <img alt="person 3" src="../assets/icon_person.png" />
                </div>
                <div className="col-6">
                  <img alt="person 1" src="../assets/icon_person.png" />
                  <img alt="person 2" src="../assets/icon_person.png" />
                </div>
              </div>
            </main>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default Creators;
